define('due-dashboard/routes/companies', ['exports', 'ember', 'due-dashboard/objects/feedback-search/filter-set', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _dueDashboardObjectsFeedbackSearchFilterSet, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  var service = _ember['default'].inject.service;
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    beforeModel: function beforeModel(transition) {
      var f = this._parseUrlFilters(transition.queryParams);

      if (f) {
        this.get('_global').updateCurrent(f);
      }
      // to remove params from url for first opened page
      if (window.location.href.includes('?')) {
        window.history.pushState({}, '', window.location.href.split('?')[0]);
      }
    },

    // private:

    _global: service('feedback-search/global'),

    _parseUrlFilters: function _parseUrlFilters(params) {
      var props = {};

      if (params.filterStartDate && params.filterEndDate) {
        props.completed_at = { start: params.filterStartDate, end: params.filterEndDate };
      }
      if (params.filters) {
        var f = JSON.parse(params.filters);

        if (f.profiles) {
          props.profiles = f.profiles.mapBy('name');
        }
        if (f.surveys) {
          props.surveys = f.surveys.mapBy('id');
        }
        if (f.resolved) {
          props.status = f.resolved;
        }
        if (f.languages) {
          props.languages = f.languages.mapBy('id');
        }
        if (f.sentiments) {
          (function () {
            var names = ['negative', 'neutral', 'positive'],
                indexes = f.sentiments.mapBy('value');

            props.sentiments = indexes.map(function (i) {
              return names[i];
            });
          })();
        }
        if (f.segments) {
          props.segments = _.mapValues(f.segments, function (v) {
            return v.split(',');
          });
        }
        if (f.excluded_tags) {
          var ts = _(f.excluded_tags).keyBy('id').mapValues(function (_) {
            return ['allfeedback'];
          });

          props.tags = { included: 'not_tagged', tags_sentiments: ts };
        }
        if (f.tags || f.tags_sentiments) {
          var ts = f.tags_sentiments;

          if (!ts && f.tags) {
            ts = f.tags.keyBy('id').mapValues(function (_) {
              return ['allfeedback'];
            });
          }
          props.tags = { included: f.tagged || 'tagged', tags_sentiments: ts };
        }
      }
      if (_.isEmpty(props)) {
        return null;
      }

      return this.get('_global.DEFAULT_FILTERS').merge(_dueDashboardObjectsFeedbackSearchFilterSet['default'].createWith(this.container, props));
    }
  });

  // Example filters to be JSON.stringify + url encoded
  //
  // {
  //   'comment': 'with',
  //   'resolved': 'resolved',
  //   'profiles': [
  //     { 'name': 'promoter' }
  //   ],
  //   'surveys': [
  //     { 'id': '38e89fd2-05ad-40f1-b5de-4b2d21cac4bd' },
  //   ],
  //   'tagged': 'tagged',
  //   'tags': [
  //     { 'id': '868584a6-ae62-420f-83fb-8420fc86b376' },
  //     { 'id': 'd8123fdf-72c6-433b-b406-946e7e8c1f28' },
  //     { 'id': 'be6c5386-9158-4a56-a63b-2ae28f55cc48' },
  //   ],
  //   'excluded_tags': [
  //     { 'id': '868584a6-ae62-420f-83fb-8420fc86b376' },
  //     { 'id': 'd8123fdf-72c6-433b-b406-946e7e8c1f28' }
  //   ],
  //   'deleted': 'deleted',
  //   'segments': {
  //     'd3b40972-dd7c-47b9-b512-8110cb3b858a': 'Chrome',
  //     '001da32a-b277-4f29-95df-4c209d6a7954': 'value1,value2',
  //     'c94316da-fbe5-401f-b3f2-614bd7fbfbff': 'desktop',
  //   },
  //   'languages': [
  //     { 'id': 'd3e73c5c-7ac3-4468-9b52-adcd6c29a6e2' },
  //     { 'id': 'cf6accb7-1fa4-4abb-8486-043a01996fd9' }
  //   ],
  //   'sentiments': [
  //     { 'value': 2 },
  //     { 'value': 1 },
  //   ],
  //   'tags_sentiments': {
  //     '868584a6-ae62-420f-83fb-8420fc86b376': [],
  //     'd8123fdf-72c6-433b-b406-946e7e8c1f28': [],
  //     'be6c5386-9158-4a56-a63b-2ae28f55cc48': ['positive'],
  //   }
  // }
});