define('due-dashboard/components/dashboard-filters', ['exports', 'ember', 'due-dashboard/objects/feedback-search/filter-set', 'moment'], function (exports, _ember, _dueDashboardObjectsFeedbackSearchFilterSet, _moment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /* global _ */

  var Component = _ember['default'].Component;
  var RSVP = _ember['default'].RSVP;
  var computed = _ember['default'].computed;
  var service = _ember['default'].inject.service;
  exports['default'] = Component.extend({

    elementId: 'dashboard-filters',

    // Default state
    defaultFilterSet: computed.readOnly('_global.DEFAULT_FILTERS'),

    // The FilterSet edited by this component. Defaults to the legacy filters service current filter-set
    filterSet: computed.readOnly('_global.current'),

    // Callback fired when edited FilterSet has been updated. Receives the updated FilterSet as only argument.
    filterSetUpdated: null,

    // Legacy update callback.
    filtersUpdated: null,

    // private:

    init: function init() {
      this._super.apply(this, arguments);
      this._resetModel();
    },

    _global: service('feedback-search/global').readOnly(),

    _uiState: 'closed',
    _model: null,
    _reloadFilterSelect: false,
    _notifying: RSVP.resolve(),

    _startDate: computed.readOnly('filterSet.completed_at.start'),
    _endDate: computed.readOnly('filterSet.completed_at.end'),
    _minDate: computed(function () {
      return (0, _moment['default'])("2000-01-01").startOf('day').toDate();
    }).readOnly(),
    _maxDate: computed(function () {
      return (0, _moment['default'])().endOf('day').toDate();
    }).readOnly(),

    _searches: computed(function () {
      return this.store.findAll('search');
    }).readOnly(),

    _allSegments: computed(function () {
      return this.store.query('segment', {
        filter: { all: false, display: 'filter' },
        page: { number: 1, size: 200 }
      });
    }).readOnly(),

    _editedFilterTypes: computed.mapBy('_model.filters', 'filter_type').readOnly(),
    _addableFilterTypes: computed.setDiff('_global.ALLOWED_FILTER_TYPES', '_editedFilterTypes').readOnly(),
    _addableFilters: computed.map('_addableFilterTypes', function (type) {
      var key = type;

      if (['tags', 'sentiments', 'languages'].contains(type)) {
        key = key.replace(/s$/, '');
      }
      return _extends({}, _dueDashboardObjectsFeedbackSearchFilterSet['default'].defaultFilter(type), {
        title: this.get('i18n').t('digest.filter.' + key)
      });
    }).readOnly(),
    _segmentFilters: computed.filterBy('_model.filters', 'filter_type', 'segment').readOnly(),
    _selectedSegments: computed.map('_segmentFilters.@each.value', function (filter) {
      var id = filter.get('value.id');

      return this.get('store').peekOrFind('segment', id);
    }).readOnly(),
    _addableSegments: computed.setDiff('_allSegments', '_selectedSegments').readOnly(),
    _segmentNestedOptions: computed.map('_addableSegments.@each.{name,id}', function (segment) {
      return {
        filter_type: 'segments',
        title: segment.get('name'),
        value: { id: segment.id, values: [] }
      };
    }).readOnly(),
    _segmentOption: computed('_segmentNestedOptions.[]', function () {
      return {
        groupName: {
          filter_type: 'segments',
          title: this.get('i18n').t('digest.filter.segment')
        },
        options: [].concat(_toConsumableArray(this.get('_segmentNestedOptions')))
      };
    }).readOnly(),
    _addableOptions: computed('_addableFilters.[]', '_segmentOption', function () {
      return [].concat(_toConsumableArray(this.get('_addableFilters')), [this.get('_segmentOption')]);
    }).readOnly(),

    _filterButtonText: computed('i18n.locale', '_hasDefaultFilters', function () {
      if (this.get('_hasDefaultFilters')) {
        return this.get('i18n').t('sidebar.filters.title');
      } else {
        return this.get('i18n').t('sidebar.filters.edit');
      }
    }).readOnly(),

    _displaySaveButton: computed('_model.hasDirtyAttributes', function () {
      var model = this.get('_model');

      return !model || model.get('hasDirtyAttributes');
    }).readOnly(),

    _searchBarDisplay: false,

    _hasDefaultFilters: computed('filterSet', 'defaultFilterSet', function () {
      var default_filters = this.get('defaultFilterSet').persistentFilters(),
          current_filters = this.get('filterSet').persistentFilters();

      return _.isEqual(current_filters, default_filters);
    }).readOnly(),

    _displayFilterPanel: function _displayFilterPanel() {
      this.set('_searchBarDisplay', false);
    },

    _saveSearch: function _saveSearch() {
      var model = this.get('_model'),
          saving = model.validateStrict().then(function (model) {
        return model.save();
      });

      saving['catch'](_ember['default'].K);
      this.set('savingPromise', saving);
      return saving;
    },

    _notifyChange: function _notifyChange(updated) {
      var _this = this;

      var current = this.get('filterSet');

      if (updated.isEqual(current)) {
        return RSVP.resolve(null);
      }
      return _.tap(this._notifyUpdate(updated), function (notifying) {
        _this.set('_notifying', notifying);
      });
    },

    _notifyUpdate: function _notifyUpdate(filter_set) {
      var f = this.get('filterSetUpdated'),
          f_legacy = this.get('filtersUpdated');
      var retval = undefined;

      if (f) {
        retval = f(filter_set);
      } else if (f_legacy) {
        // This dependency to the updateCurrent method must be removed eventually.
        this.get('_global').updateCurrent(filter_set);
        retval = f_legacy();
      }
      return RSVP.resolve(retval);
    },

    _beginEdition: function _beginEdition() {
      var input = this.get('filterSet');

      this._resetTo(input);
      this.set('_savedModel', this.get('_model'));
    },

    _commitEdition: function _commitEdition() {
      var updated = this.get('filterSet').copy(),
          updated_persistent_props = this._fromSearchFormat(this.get('_model.filters')).getProperties(_dueDashboardObjectsFeedbackSearchFilterSet['default'].PERSISTENT_FILTER_TYPES);

      updated.setProperties(updated_persistent_props);
      return this._notifyChange(updated);
    },

    _rollbackEdition: function _rollbackEdition() {
      var saved = this.get('_savedModel');

      if (saved) {
        this._setModel(saved);
        this.set('_savedModel', null);
      }
    },

    _toSearchFormat: function _toSearchFormat(input) {
      var model = this.get('_model'),
          remaining = input.persistentFilters();
      var output = [];

      if (model) {
        var model_filters = _.cloneDeep(model.get('filters'));

        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = model_filters[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var mf = _step.value;

            if (mf.filter_type === 'segments') {
              if (_.has(remaining.segments, mf.value.id)) {
                _.unset(remaining.segments, mf.value.id);
                output.push(mf);
              }
            } else if (_.has(remaining, mf.filter_type)) {
              _.unset(remaining, mf.filter_type);
              output.push(mf);
            } else {
              break;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator['return']) {
              _iterator['return']();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      }

      var remaining_sorted = _.sortBy(Object.entries(remaining), function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2);

        var type = _ref2[0];
        var _ = _ref2[1];

        var order = ['comment', 'status', 'surveys'],
            found = order.indexOf(type);

        return found === -1 ? order.length : found;
      });

      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = remaining_sorted[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var _step2$value = _slicedToArray(_step2.value, 2);

          var type = _step2$value[0];
          var value = _step2$value[1];

          if (type === 'segments') {
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
              for (var _iterator3 = Object.entries(value)[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                var _step3$value = _slicedToArray(_step3.value, 2);

                var id = _step3$value[0];
                var values = _step3$value[1];

                output.push({ filter_type: type, value: { id: id, values: values } });
              }
            } catch (err) {
              _didIteratorError3 = true;
              _iteratorError3 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion3 && _iterator3['return']) {
                  _iterator3['return']();
                }
              } finally {
                if (_didIteratorError3) {
                  throw _iteratorError3;
                }
              }
            }
          } else {
            output.push({ filter_type: type, value: value });
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2['return']) {
            _iterator2['return']();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }

      return output;
    },

    _fromSearchFormat: function _fromSearchFormat(filters) {
      var props = _(_.cloneDeep(filters)).groupBy('filter_type').mapValues(function (values) {
        return values.mapBy('value');
      }).mapValues(function (group, type) {
        if (type === 'segments') {
          return _.reduce(group, function (acc, _ref3) {
            var id = _ref3.id;
            var values = _ref3.values;

            return _extends({}, acc, _defineProperty({}, id, values));
          }, {});
        } else {
          return group[0];
        }
      }).value();

      return _dueDashboardObjectsFeedbackSearchFilterSet['default'].createWith(this.container, props);
    },

    _resetModel: function _resetModel() {
      this.set('_model', this.store.createRecord('search'));
    },

    _setModel: function _setModel(model) {
      this._rollbackModel();
      this.set('_model', model);
    },

    _destroyModel: function _destroyModel() {
      var _this2 = this;

      var model = this.get('_model'),
          current = this._fromSearchFormat(model.get('filters'));

      if (model === this.get('_savedModel')) {
        this.set('_savedModel', null);
      }
      return model.destroyRecord().then(function () {
        _this2._resetModel();
        _this2._resetTo(current);
      });
    },

    _rollbackModel: function _rollbackModel() {
      var model = this.get('_model');

      if (!model) {
        return;
      }

      if (model.get('isNew')) {
        this._resetToDefault();
      } else {
        model.rollbackAttributes();
      }
    },

    _resetTo: function _resetTo(filter_set) {
      this.set('_model.filters', this._toSearchFormat(filter_set));
    },

    _resetToDefault: function _resetToDefault() {
      return this._resetTo(this.get('defaultFilterSet'));
    },

    actions: {

      openModal: function openModal() {
        this._beginEdition();
        this.set('_uiState', 'open');
      },

      closeModal: function closeModal() {
        this._rollbackEdition();
        this.set('_uiState', 'closed');
      },

      applyFilters: function applyFilters() {
        this.set('_uiState', 'closed');
        return this._commitEdition();
      },

      rangePicked: function rangePicked(start, end, pickedButton) {
        var updated = this.get('filterSet').copy();

        updated.set('completed_at', { start: start, end: end });
        return this._notifyChange(updated);
      },

      resetFilters: function resetFilters() {
        this._resetModel();
        this._resetToDefault();
        return this._commitEdition();
      },

      addFilter: function addFilter(filter) {
        var filters = this.get('_model.filters'),
            attrs = _.pick(filter, 'filter_type', 'value');

        filters.pushObject(attrs);
        this.toggleProperty('_reloadFilterSelect');
      },

      deleteFilter: function deleteFilter(filter) {
        this.get('_model.filters').removeObject(filter);
        this.toggleProperty('_reloadFilterSelect');
      },

      chooseNewFilter: function chooseNewFilter() {
        this.get('addFilterController').actions.open();
      },

      newSearch: function newSearch() {
        this._resetModel();
        this._resetToDefault();
        this._displayFilterPanel();
      },

      createSearch: function createSearch() {
        this.set('_uiState', 'edit');
      },

      setSearch: function setSearch(search) {
        this._setModel(search);
        this.set('_uiState', 'open');
        this._displayFilterPanel();
      },

      modifySearch: function modifySearch() {
        this.set('_uiState', 'edit');
      },

      rollbackSearch: function rollbackSearch() {
        this._rollbackModel();
        this.set('_uiState', 'open');
      },

      saveSearch: function saveSearch() {
        var _this3 = this;

        return this._saveSearch().then(function () {
          _this3.set('_uiState', 'open');
        });
      },

      tryToDelete: function tryToDelete() {
        this.set('_uiState', 'deleting');
      },

      cancelDelete: function cancelDelete() {
        this.set('_uiState', 'open');
      },

      deleteSearch: function deleteSearch() {
        var _this4 = this;

        this._destroyModel().then(function () {
          _this4.set('_uiState', 'open');
        });
      },

      toggleShared: function toggleShared() {
        this.toggleProperty('_model.shared');
      },

      switchPanel: function switchPanel() {
        this.toggleProperty('_searchBarDisplay');
      },

      setAddFilterController: function setAddFilterController(remoteController) {
        this.set('addFilterController', remoteController);
      }
    }
  });
});